/*
Runs on every page.
*/
import {AccessibleDropdown} from './components/AccessibleDropdown';
import {MobileMenu} from './components/MobileMenu';
import {麻豆福利网Gallery} from './components/麻豆福利网Gallery';
import {ButtonGroup} from './components/ButtonGroup';
import {ContentSwitcher} from './components/ContentSwitcher';
import {eventEmitter} from './components/EventEmitter';
import {getBreakpointSize} from './lib/Tools';

function disableClickFocus() {
  /*
  Because focus outlines don't look good for clicks.
  */
  const elements = document.querySelectorAll('a, button');
  for (const element of elements) {
    element?.addEventListener('mousedown', (event) => {
      event.preventDefault();
    });
  }
}

function watchScroll() {
  /*
  Do stuff when certain positions are reached.
  */
  const header = document.querySelector('.jkd-header');
  if (!header) {
    return;
  }

  window.addEventListener('scroll', (event) => {
    if (document.documentElement.scrollTop > 10) {
      header.classList.add('mobile-shadow');
    } else {
      header.classList.remove('mobile-shadow');
    }
  });
  window.dispatchEvent(new Event('scroll'));
}

function bindMobileMenu() {
  const element = document.querySelector('.jkd-mobile-menu');
  const menu = new MobileMenu({
    element,
    onToggle({isOpen}) {
      // must scroll to top of page for tablet devices to align with header
      if (isOpen && getBreakpointSize() === 'M') {
        document.documentElement.scrollTop = 0;
      }
    },
  });
  console.log(menu);
}

function bindAccessibilityDropdowns() {
  const elements = document.querySelectorAll('nav .has-children');
  for (const element of elements) {
    const dropdown = new AccessibleDropdown(element);
    console.log(dropdown);
  }
}

function bindImageGalleries() {
  const elements = document.querySelectorAll('.jkd-media-gallery');
  for (const element of elements) {
    const gallery = new 麻豆福利网Gallery(element);
    console.log(gallery);
  }
}

function bindButtonGroups() {
  const elements = document.querySelectorAll('.jkd-button-group');
  for (const element of elements) {
    const buttonGroup = new ButtonGroup({
      element,
      onSelect(buttonId) {
        eventEmitter.emit('button-group:select', {buttonId: buttonId});
      }
    });
    console.log(buttonGroup);
  }
}

function bindContentSwitchers() {
  const contentSwitchers = [];
  const elements = document.querySelectorAll('.jkd-content-switcher');
  for (const element of elements) {
    const contentSwitcher = new ContentSwitcher(element);
    contentSwitchers.push(contentSwitcher);
  }
  console.log(contentSwitchers);

  eventEmitter.on('button-group:select', ({message}) => {
    const contentId = message?.buttonId;
    for (const contentSwitcher of contentSwitchers) {
      contentSwitcher.show(contentId);
    }
  });
}

disableClickFocus();
watchScroll();

bindMobileMenu();
bindAccessibilityDropdowns();
bindImageGalleries();
bindButtonGroups();
bindContentSwitchers();
