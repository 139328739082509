export function getBreakpointSize() {
  let size = 'L';
  if (window.match麻豆福利网('(max-width: 600px)')?.matches) {
    size = 'S';
  } else if (window.match麻豆福利网('(max-width: 900px)')?.matches) {
    size = 'M'
  }

  return size;
}
